export const homepageMobileVideos = [
  {
    titleKey: 'homepage.secondary_title_1',
    name: 'Secondary - Hammers',
    cldId: 'assets/homepage/2023/videos/peelStick',
  },
  {
    titleKey: 'homepage.secondary_title_2',
    name: 'Secondary - Adjust',
    cldId: 'assets/homepage/2023/videos/Adjust',
  },
  {
    titleKey: 'homepage.secondary_title_3',
    name: 'Secondary - Marks',
    cldId: 'assets/homepage/2023/videos/noDamage',
  },
]

export const homepageDesktopVideos = [
  {
    titleKey: 'homepage.secondary_title_1_desktop',
    name: 'Secondary - Hammers',
    cldId: 'assets/homepage/2023/videos/peelStick',
  },
  {
    titleKey: 'homepage.secondary_title_2',
    name: 'Secondary - Adjust',
    cldId: 'assets/homepage/2023/videos/Adjust',
  },
  {
    titleKey: 'homepage.secondary_title_3',
    name: 'Secondary - Marks',
    cldId: 'assets/homepage/2023/videos/noDamage',
  },
]

export const artHomepageMobileVideos = [
  {
    titleKey: 'homepage.secondary_title_1',
    name: 'Secondary - Hammers',
    cldId: 'assets/art/homepage/videos/peelStick',
    folder: 'art/homepage',
  },
  {
    titleKey: 'homepage.secondary_title_2',
    name: 'Secondary - Adjust',
    cldId: 'assets/art/homepage/videos/Adjust',
    folder: 'art/homepage',
  },
  {
    titleKey: 'homepage.secondary_title_3',
    name: 'Secondary - Marks',
    cldId: 'assets/art/homepage/videos/noDamage',
    folder: 'art/homepage',
  },
]

export const artHomepageDesktopVideos = [
  {
    titleKey: 'homepage.secondary_title_1_desktop',
    name: 'Secondary - Hammers',
    cldId: 'assets/art/homepage/videos/peelStick',
    folder: 'art/homepage',
  },
  {
    titleKey: 'homepage.secondary_title_2',
    name: 'Secondary - Adjust',
    cldId: 'assets/art/homepage/videos/Adjust',
    folder: 'art/homepage',
  },
  {
    titleKey: 'homepage.secondary_title_3',
    name: 'Secondary - Marks',
    cldId: 'assets/art/homepage/videos/noDamage',
    folder: 'art/homepage',
  },
]
