import React, { Component } from 'react'
import './GenericBanner.scss'
import classNames from 'classnames'
import { translateManager } from '../../services/TranslateManager'
import FlipClock from '../FlipClock/FlipClock'
import { genericBannerManager } from '../../services/GenericBannerManager'
import { analytics } from '../../services/Analytics/Analytics'
import DesktopHomepageGenericBanner from '../DesktopHomepageGenericBanner/DesktopHomepageGenericBanner'

class GenericBanner extends Component {
  state = {
    bannerConfig: null,
    visible: false,
  }

  componentDidMount() {
    this.initBannerFromConfig()
  }

  componentWillUnmount() {
    if (this.hideBannerAnimationTimer) {
      clearTimeout(this.hideBannerAnimationTimer)
    }
  }

  initBannerFromConfig() {
    const bannerConfig = genericBannerManager.getBanner()
    genericBannerManager.ensureBannerPromoIsApplied(bannerConfig)
    if (!bannerConfig) {
      this.setState({ bannerConfig: null, visible: false })
    } else {
      const timeLeft = genericBannerManager.getTimeLeft(bannerConfig)
      const visible = genericBannerManager.isVisible(bannerConfig)

      if (timeLeft <= 0) {
        this.hideBannerAnimationTimer = setTimeout(() => {
          this.setState({ visible: false })
          this.hideBannerAnimationTimer = setTimeout(
            () => this.setState({ bannerConfig: null }),
            750
          )
        }, timeLeft)
      }
      this.setState({ bannerConfig })
      // This is to invoke the visible only after the first render to create the animation
      setTimeout(() => {
        this.setState({ visible })
        if (visible) {
          analytics.trackOncePerSession('Generic Banner Shown', {
            Banner: bannerConfig.identifier,
          })
        }
      }, 100)
    }
  }

  get title() {
    return this.state.bannerConfig.titleText
      ? this.state.bannerConfig.titleText[translateManager.getLanguage()]
      : ''
  }

  get subtitle() {
    return this.state.bannerConfig.subtitleText
      ? this.state.bannerConfig.subtitleText[translateManager.getLanguage()] ||
          ''
      : ''
  }

  get hasTimer() {
    return this.state.bannerConfig.hasTimer
  }

  get endTime() {
    return this.state.bannerConfig.timerDeadline
  }

  render() {
    const { bannerConfig, visible } = this.state
    const { desktopHomepage } = this.props
    if (!bannerConfig) {
      return null
    }
    const clockProps = {}
    if (this.hasTimer) {
      Object.assign(clockProps, { longDate: this.endTime })
    }

    if (desktopHomepage) {
      return (
        <DesktopHomepageGenericBanner
          visible={visible}
          title={this.title}
          subtitle={this.subtitle}
          clockProps={clockProps}
        />
      )
    }

    return (
      <div
        className={classNames('GenericBanner', {
          visible,
          is3D: this.state.is3D,
        })}
      >
        <div className="banner-content">
          <div className="text">
            <div className="title">{this.title}</div>
            <div className="subtitle">{this.subtitle}</div>
          </div>
          {this.hasTimer && <FlipClock {...clockProps} />}
        </div>
      </div>
    )
  }
}

export default GenericBanner
