import { useRef } from 'react'
import { type VideoRefType } from '../HomePageVideo/HomePageVideo'
import * as S from './FullScreenVideoSection.styles'
import { useTransform, useScroll } from 'framer-motion'
import useScreenSize from '../../../../hooks/useScreenSize'

type Props = {
  cldId: string
  folder?: string
  videoText: string
}

export default function FullScreenVideoSection({
  cldId,
  folder,
  videoText,
}: Props) {
  const isMediumScreen = useScreenSize('md')
  const videoRef = useRef<VideoRefType>(null)
  const { scrollYProgress } = useScroll({
    target: videoRef.current?.containerRef,
    offset: ['start end', 'start start'],
  })
  const translateY = useTransform(scrollYProgress, [0, 1], [5, 0])
  const scale = useTransform(scrollYProgress, [0.6, 1], [0.9, 1])
  const opacity = useTransform(scrollYProgress, [0, 0.8], [0, 1])

  return (
    <S.FullScreenVideo
      ref={videoRef}
      cldId={cldId}
      folder={folder}
      style={isMediumScreen ? { opacity: 1 } : { opacity, translateY, scale }}
      name="Fullscreen"
      type="Video"
      fetchPriority="low"
      cldWidth={isMediumScreen ? 2000 : undefined}
    >
      <S.FullScreenVideoWrapper>
        <S.FullScreenVideoText>{videoText}</S.FullScreenVideoText>
      </S.FullScreenVideoWrapper>
    </S.FullScreenVideo>
  )
}
