import { styled } from 'styled-components'
import { onXXLargeScreen } from '../../mixtiles-web-common'

export const Container = styled.div`
  background: ${(props) => props.theme.colors.grayBackgroundWhite};
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
`

export const ScaleContainer = styled.div`
  ${onXXLargeScreen()} {
    transform: scale(1.25);
  }
`

export const TopBarLeftContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
`

export const MobileInitialCta = styled.button`
  border-radius: 7px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  color: ${({ theme }) => theme.colors.white};
  background: transparent;
  font-size: 14px;
  line-height: 25px;
  font-weight: 600;
  letter-spacing: 0.05px;
  text-align: center;
  box-sizing: content-box;
  height: 25px;
  padding: 0 12px;

  &:hover {
    transform: scale(0.95);
    transition: transform 0.2s;
  }
`
