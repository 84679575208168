import * as S from './SecondaryVideoGallery.styles'
import React, { useEffect, useRef, useState } from 'react'
import { type VideoRefType } from '../HomePageVideo/HomePageVideo'
import { getVideoPercentagePlayed } from '../../../../utils/videoUtils'
import { capitalizeFirstLetter } from '../../../../utils/utils'
import { useExperimentManager } from '../../../../services/ExperimentManager/ExperimentManager'
import { useTranslation } from 'react-i18next'
import { type MotionValue, useMotionValue, useTransform } from 'framer-motion'

type Props = {
  videos: {
    titleKey: string
    name: string
    cldId: string
    folder?: string
  }[]
}

export default function SecondaryVideoGallery({ videos }: Props) {
  const { t } = useTranslation()
  const videoRef = useRef<VideoRefType>(null)
  const [currentlyPlayedIndex, setCurrentlyPlayedIndex] = useState<number>(0)
  const videoPercent = useMotionValue(0)
  const notPlayingVideoPercent = useMotionValue(0)

  const experimentManager = useExperimentManager()

  const handleVideoEnd = () => {
    videoPercent.set(0)
    setCurrentlyPlayedIndex((prevIndex) => {
      return prevIndex === videos.length - 1 ? 0 : prevIndex + 1
    })
  }

  const handleVideoTitleClick = (index: number) => {
    videoPercent.set(0)
    setCurrentlyPlayedIndex(index)
  }

  useEffect(() => {
    if (!videoRef.current) return

    const updatePlayedPercentage = () => {
      if (videoRef?.current?.videoRef.current) {
        videoPercent.set(
          getVideoPercentagePlayed(videoRef.current.videoRef.current)
        )
      }
    }

    videoRef.current.videoRef.current?.addEventListener(
      'timeupdate',
      updatePlayedPercentage
    )

    return () => {
      if (!videoRef.current) return
      videoRef.current.videoRef.current?.removeEventListener(
        'timeupdate',
        updatePlayedPercentage
      )
    }
  }, [currentlyPlayedIndex])

  const homepageNewTitle = experimentManager.isEnabled('homepage-new-title')
  return (
    <S.Container>
      <S.TextContainer>
        <S.Title>
          {homepageNewTitle
            ? 'Meet magnetic hanging'
            : t('homepage.secondary_title')}
        </S.Title>
        <S.TitlesSection>
          {videos.map((v, index) => {
            const isCurrent = index === currentlyPlayedIndex
            return (
              <VideoTitle
                key={v.titleKey}
                title={capitalizeFirstLetter(t(v.titleKey))}
                isCurrent={isCurrent}
                progressPercent={
                  isCurrent ? videoPercent : notPlayingVideoPercent
                }
                onClick={() => {
                  handleVideoTitleClick(index)
                }}
              />
            )
          })}
        </S.TitlesSection>
      </S.TextContainer>
      <S.VideoContainer>
        <S.Video
          ref={videoRef}
          cldId={videos[currentlyPlayedIndex].cldId}
          name={videos[currentlyPlayedIndex].name}
          folder={videos[currentlyPlayedIndex].folder}
          type="Video"
          fetchPriority="low"
          onVideoEnd={handleVideoEnd}
          loop={false}
          cldWidth={2000}
        />
      </S.VideoContainer>
    </S.Container>
  )
}

function VideoTitle({
  title,
  isCurrent,
  progressPercent,
  onClick,
}: {
  title: string
  isCurrent: boolean
  progressPercent: MotionValue<number>
  onClick: () => void
}) {
  const lastHeight = useRef<string>('0')
  const heightPercentage = useTransform(progressPercent, (value) => `${value}%`)

  useEffect(() => {
    const unsubscribe = progressPercent.on('change', (value) => {
      if (!isCurrent || value === 0) return
      lastHeight.current = `${value}%`
    })

    return () => {
      unsubscribe()
    }
  }, [progressPercent, isCurrent])

  return (
    <S.VideoTitleContainer>
      <S.VideoProgress>
        <S.VideoProgressInner
          style={{
            height: isCurrent ? heightPercentage : lastHeight.current,
            opacity: isCurrent ? 1 : 0,
          }}
        />
      </S.VideoProgress>
      <S.VideoTitle $highlighted={isCurrent} onClick={onClick}>
        {title}
      </S.VideoTitle>
    </S.VideoTitleContainer>
  )
}
