import { styled } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import { onMediumScreen, onXXLargeScreen } from 'mixtiles-web-common'

export const FullScreenVideo = styled(HomePageVideo)`
  height: 90vh;
  will-change: transform, opacity;
  margin-top: 20px;

  ${onMediumScreen()} {
    height: 83vh;
  }
`
export const FullScreenVideoWrapper = styled.div`
  padding: 0 10px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FullScreenVideoText = styled.h1`
  text-align: center;
  font-size: 29.5px;
  font-weight: 700;
  line-height: 110%;
  color: ${({ theme }) => theme.colors.white};
  letter-spacing: -0.01px;
  text-shadow: 0 4px 34px rgba(0, 0, 0, 0.55);
  width: 100%;
  max-width: 350px;

  ${onMediumScreen()} {
    font-size: 48px;
    font-weight: 600;
    line-height: 130%;
    max-width: 70%;
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`
