import { styled } from 'styled-components'
import IllustrationComponent from 'mixtiles-web-common/elements/Illustration/Illustration'
import {
  onLargeScreen,
  onMediumScreen,
  onXXLargeScreen,
} from 'mixtiles-web-common'

export const Container = styled.div`
  margin: 20px 0 112px;
  width: 100%;
`

export const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  margin-bottom: 48px;

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const IllustrationsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 18px;
  margin: 0 auto;

  ${onMediumScreen()} {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  ${onLargeScreen()} {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
  }
`

export const IllustrationBackground = styled.div`
  background: ${({ theme }) => theme.colors.grayBright};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 28px;

  ${onXXLargeScreen} {
    height: 280px;
  }
`

export const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${onXXLargeScreen} {
    transform: scale(1.25);
    transform-origin: center;
  }
`

export const Illustration = styled(IllustrationComponent)`
  width: 119px;
  height: 119px;
  margin-bottom: 13px;
`

export const IllustrationTitle = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
`

export const IllustrationSubtitle = styled.div`
  font-size: 17px;
  line-height: 24px;
  text-align: center;
  max-width: 80%;
`
