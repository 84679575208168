import * as S from './CoveredSection.styles'
import useScreenSize from '../../../../hooks/useScreenSize'
import PhotoBooksCoveredSection from '../../../PhotoBookHomePage/Components/CoveredSection/CoveredSection'
import startCase from 'lodash/startCase'
import { ILLUSTRATIONS } from 'mixtiles-web-common/consts/illustrations.const'
import { usePricing } from '../../../../components/Pricing/PricingProvider'
import { useMemo } from 'react'
import { formatPrice } from '../../../../utils/utils'
import { useTranslation } from 'react-i18next'

function CoveredSection() {
  const { t } = useTranslation()
  const isMediumScreen = useScreenSize('md')
  const isLargeScreen = useScreenSize('lg')
  const { discounts, pricingData } = usePricing()

  const staticIllustrations = useMemo(
    () => [
      {
        name: ILLUSTRATIONS.HOMEPAGE.RATING,
        title: t('homepage.covered.rated', {
          v1: '4.9/5',
        }),
        subtitle: t('homepage.covered.rated_subtitle', {
          v1: '120k',
        }),
      },
      {
        name: ILLUSTRATIONS.HOMEPAGE.SATISFACTION,
        title: startCase(t('pitch.icons.satisfaction.title')),
        subtitle: t('pitch.icons.satisfaction.text'),
      },
    ],
    []
  )

  const displayIllustrations = useMemo(() => {
    const discount = discounts.value.find(
      (t) => t.discountId === 'FREE_SHIPPING_OVER'
    )
    const discountPrice =
      discount && pricingData.currency
        ? formatPrice(
            discount.shippingBuyOverData.buyOverPrice,
            pricingData.currency
          )
        : ''
    const shippingIllustration = {
      name: ILLUSTRATIONS.HOMEPAGE.SHIPPING,
      title: t('homepage.covered.shipping'),
      subtitle: discountPrice
        ? t('homepage.covered.shipping_subtitle', {
            v1: discountPrice,
          })
        : t('homepage.covered.shipping_free_subtitle'),
    }
    const illustrations = [shippingIllustration, ...staticIllustrations]
    if (!isLargeScreen) {
      const SUPPORT_ILLUSTRATION = {
        name: ILLUSTRATIONS.HOMEPAGE.SUPPORT,
        title: t('homepage.covered.support_short'),
        subtitle: t('homepage.covered.support_subtitle'),
      }
      illustrations.push(SUPPORT_ILLUSTRATION)
    }
    return illustrations
  }, [isLargeScreen, discounts])

  return isMediumScreen ? (
    <S.Container>
      <S.Title>{t('homepage.covered.title')}</S.Title>
      <S.IllustrationsContainer>
        {displayIllustrations.map(({ name, title, subtitle }) => (
          <CoveredIllustration
            key={name}
            name={name}
            title={title}
            subtitle={subtitle}
          />
        ))}
      </S.IllustrationsContainer>
    </S.Container>
  ) : (
    <PhotoBooksCoveredSection communicateFreeShipping={false} />
  )
}

function CoveredIllustration({
  name,
  title,
  subtitle,
}: {
  name: string
  title: string
  subtitle: string
}) {
  return (
    <S.IllustrationBackground>
      <S.IllustrationContainer>
        <S.Illustration name={name} />
        <S.IllustrationTitle>{title}</S.IllustrationTitle>
        <S.IllustrationSubtitle>{subtitle}</S.IllustrationSubtitle>
      </S.IllustrationContainer>
    </S.IllustrationBackground>
  )
}

export default CoveredSection
