import { css, keyframes, styled } from 'styled-components'
import {
  onLargeScreen,
  onMediumScreen,
  onExtraLargeScreen,
  onXXLargeScreen,
  Button,
} from 'mixtiles-web-common'
import { m } from 'framer-motion'

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.black};
  align-items: center;
  user-select: none;
`

const VList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
`

export const WidthCapList = styled(VList)<{
  $fullscreen?: boolean
}>`
  max-width: 450px;

  ${onMediumScreen()} {
    max-width: ${({ $fullscreen }) => ($fullscreen ? '100%' : '90%')};
    overflow: unset;
  }

  ${onLargeScreen()} {
    max-width: ${({ $fullscreen }) => ($fullscreen ? '100%' : '1280px')};
  }

  ${onExtraLargeScreen()} {
    max-width: ${({ $fullscreen }) => ($fullscreen ? '100%' : '1400px')};
  }

  ${onXXLargeScreen()} {
    max-width: ${({ $fullscreen }) => ($fullscreen ? '100%' : '1700px')};
  }
`

export const WallContainer = styled(VList)`
  background-color: #f5f5f7;
`

export const CommunityContainer = styled(VList)`
  background-image: linear-gradient(
    0deg,
    ${({ theme }) => theme.colors.white} 0%,
    ${({ theme }) => theme.colors.grayBackgroundWhite} 100%
  );
`

export const EndingContainer = styled(VList)`
  background: linear-gradient(180deg, #fbf6f6 0%, #fbf9f9 100%);

  ${onMediumScreen()} {
    background: white;
  }
`

export const FooterContainer = styled(VList)`
  background-color: ${({ theme }) => theme.colors.white};

  ${onMediumScreen()} {
    background-color: ${({ theme }) => theme.colors.grayBackgroundWhite};
  }
`

export const StickyTopBar = styled(m.div)`
  z-index: 100;
  position: fixed;
  top: 0;
  background: linear-gradient(
    180deg,
    #f2e9e9 0%,
    rgba(242, 233, 233, 0.8) 100%
  );

  backdrop-filter: blur(52px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  width: 100%;
  padding: 12px;

  ${onMediumScreen()} {
    background: rgba(251, 249, 249, 0.9);
    backdrop-filter: blur(92px);
  }

  ${onXXLargeScreen()} {
    height: 70px;
  }
`

const slide = keyframes`
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(100%);
    }
`

export const StickyTopBarCta = styled(Button)<{ $shine: boolean }>`
  height: 27px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.05px;
  box-shadow: 0px 2px 3px 0px rgba(169, 30, 84, 0.2);
  padding: 0 11px;
  overflow: hidden;

  &:hover {
    transform: scale(0.95);
    transition: transform 0.2s;
    box-shadow: 0px 2px 3px 0px rgba(169, 30, 84, 0.2);
  }

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: right;

    &:hover {
      transform: scale(1.21);
    }
  }
  ${({ $shine }) =>
    $shine &&
    css`
      &::after {
        content: '';
        position: absolute;
        inset: 0;
        animation: ${slide} 3s infinite ease-in-out;
        background: linear-gradient(
          to right,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0.4) 30%,
          rgba(255, 255, 255, 0) 60%,
          rgba(255, 255, 255, 0) 100%
        );
      }
    `}
`

export const TopBarLeftContainer = styled.div`
  display: flex;
  gap: 9px;
  align-items: center;

  ${onXXLargeScreen()} {
    transform: scale(1.25);
    transform-origin: left;
  }
`
