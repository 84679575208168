import { useRef } from 'react'
import * as S from './SecondaryVideoSection.styles'
import { useTransform, useScroll } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useNoSSR } from 'utils/NoSSR'

type Props = {
  videos: {
    titleKey: string
    name: string
    cldId: string
    folder?: string
  }[]
}

export default function SecondaryVideoSection({ videos }: Props) {
  const { t } = useTranslation()
  return (
    <S.List>
      {videos.map(({ titleKey, name, cldId, folder }) => (
        <VideoItem
          key={titleKey}
          title={t(titleKey)}
          name={name}
          cldId={cldId}
          folder={folder}
        />
      ))}
    </S.List>
  )
}

function VideoItem({
  title,
  name,
  cldId,
  folder,
}: {
  title: string
  name: string
  cldId: string
  folder?: string
}) {
  const isClient = useNoSSR()
  const containerRef = useRef<HTMLDivElement>(null)
  const { scrollYProgress } = useScroll({
    target: containerRef,
    offset: ['start end', 'start start'],
  })
  const translateY = useTransform(scrollYProgress, [0.35, 0.7], [20, 0])
  const opacity = useTransform(scrollYProgress, [0, 0.5], [0.01, 1])

  return (
    <S.ListItem
      ref={containerRef}
      style={{
        // SSR needs to render these as visible first, when client takes over it will animate
        opacity: isClient ? opacity : 1,
        translateY: isClient ? translateY : 0,
      }}
    >
      <S.Title>{title}</S.Title>
      <S.Video
        cldId={cldId}
        name={name}
        type="Video"
        fetchPriority="low"
        folder={folder}
      />
    </S.ListItem>
  )
}
