import { styled } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import { m } from 'framer-motion'

export const List = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;
`

export const ListItem = styled(m.div)`
  padding: 40px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  will-change: transform, opacity;
`

export const Title = styled.h2`
  padding: 0 40px;
  font-size: 31px;
  font-weight: 600;
  text-align: center;
  max-width: 380px;
`
export const Video = styled(HomePageVideo)`
  height: 40vh;
`
