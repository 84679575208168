import { styled, css } from 'styled-components'
import HomePageVideo from '../HomePageVideo/HomePageVideo'
import {
  onLargeScreen,
  onMediumScreen,
  onXXLargeScreen,
} from 'mixtiles-web-common'
import { m } from 'framer-motion'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 70px 0 140px;

  ${onMediumScreen()} {
    padding-left: 20px;
  }

  ${onLargeScreen()} {
    padding-left: 0;
  }

  ${onXXLargeScreen()} {
    max-width: 1700px;
    justify-content: space-between;
  }
`

export const TextContainer = styled.div`
  margin: auto;

  ${onXXLargeScreen()} {
    margin: 0;
    transform: scale(1.25);
    transform-origin: left;
  }
`

export const Title = styled.div`
  font-size: 48px;
  font-weight: 600;
  line-height: 58px;
  text-align: left;
  color: ${({ theme }) => theme.colors.black};
  max-width: 430px;
  margin-bottom: 21px;
`

export const VideoContainer = styled.div`
  width: 60vw;
  border-radius: 25px 0 0 25px;
  overflow: hidden;
  box-shadow:
    0 100px 80px 0 rgba(0, 0, 0, 0.07),
    10px 41.778px 33.422px 0 rgba(0, 0, 0, 0.05),
    20px 22.336px 17.869px 0 rgba(0, 0, 0, 0.04),
    30px 12.522px 10.017px 0 rgba(0, 0, 0, 0.04),
    40px 6.65px 5.32px 0 rgba(0, 0, 0, 0.03),
    50px 2.767px 2.214px 0 rgba(0, 0, 0, 0.02);

  ${onXXLargeScreen()} {
    width: 881px;
    height: 630px;
    border-radius: 25px;
    box-shadow:
      0px 100px 80px 0px rgba(0, 0, 0, 0.08),
      0px 41.778px 33.422px 0px rgba(0, 0, 0, 0.06),
      0px 22.336px 17.869px 0px rgba(0, 0, 0, 0.05),
      0px 12.522px 10.017px 0px rgba(0, 0, 0, 0.04),
      0px 6.65px 5.32px 0px rgba(0, 0, 0, 0.03),
      0px 2.767px 2.214px 0px rgba(0, 0, 0, 0.02);
  }
`

export const TitlesSection = styled.div``

export const VideoTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const VideoProgress = styled.div`
  width: 4px;
  height: 34px;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.colors.grayMedium};
  position: relative;
`

export const VideoProgressInner = styled(m.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: 2px;
  transition:
    opacity 0.3s ease-in-out,
    height 0.3s ease-in-out;
`

export const VideoTitle = styled.div<{ $highlighted: boolean }>`
  font-size: 30px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors.grayMidDark};
  margin: 12px auto 12px 32px;
  transition: color 0.3s ease-in-out;
  cursor: pointer;

  ${({ $highlighted }) =>
    $highlighted &&
    css`
      color: ${({ theme }) => theme.colors.primary};
      font-weight: 500;
    `}
`

export const Video = styled(HomePageVideo)`
  height: 75vh;

  ${onXXLargeScreen()} {
    height: 100%;
  }
`
