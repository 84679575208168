import React, { PureComponent } from 'react'
import classNames from 'classnames'
import './FlipClock.scss'

class FlipClock extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { visible: false }
  }

  componentDidMount() {
    if (window.FlipClock) {
      this.startCountdown()
    } else {
      document
        .querySelector('#flipclock-js')
        .addEventListener('load', () => this.startCountdown())
    }
  }

  startCountdown() {
    const endDate = new Date(this.props.longDate)
    const timeLeft = endDate.getTime() - new Date().getTime()

    // eslint-disable-next-line no-new
    new window.FlipClock(
      document.getElementById('flipclock'),
      timeLeft * 0.001,
      {
        clockFace: this.props.counterType || 'DailyCounter',
        countdown: true,
      }
    )
    this.setState({ visible: true })
  }

  render() {
    const { visible } = this.state
    return (
      <div
        className={classNames('FlipClock', 'countdown-wrapper', { visible })}
      >
        <div className="countdown" id="flipclock" />
        <div className="tooltip">
          {!this.props.hideDays && <div className="tt-content">DAYS</div>}
          {!this.props.hideDays && <div className="tt-divider" />}
          <div className="tt-content">
            {this.props.shortText ? 'HRS' : 'HOURS'}
          </div>
          <div className="tt-divider" />
          <div className="tt-content">
            {this.props.shortText ? 'MINS' : 'MINUTES'}
          </div>
          <div className="tt-divider" />
          <div className="tt-content">
            {this.props.shortText ? 'SECS' : 'SECONDS'}
          </div>
        </div>
      </div>
    )
  }
}

export default FlipClock
