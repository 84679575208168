import React, { useState } from 'react'
import useScreenSize from '../../../hooks/useScreenSize'
import useOnMount from '../../../hooks/useOnMount'
import GenericBanner from '../../../components/GenericBanner/GenericBanner'

export default function TopBarBanner() {
  const [renderBanners, setRenderBanners] = useState(false)
  const isMediumScreen = useScreenSize('md')

  useOnMount(() => {
    setTimeout(() => {
      setRenderBanners(true)
    }, 500)
  })

  if (!renderBanners) {
    return null
  }

  return <GenericBanner desktopHomepage={isMediumScreen} />
}
