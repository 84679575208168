import React from 'react'
import classNames from 'classnames'
import './DesktopHomepageGenericBanner.scss'
import FlipClock from '../FlipClock/FlipClock'

function DesktopHomepageGenericBanner({
  title,
  subtitle,
  clockProps,
  visible,
}) {
  const showClock = !!clockProps.longDate
  return (
    <div className={classNames('DesktopHomepageGenericBanner', { visible })}>
      <div className="content">
        <div className="text">
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </div>
        {showClock && <FlipClock {...clockProps} />}
      </div>
    </div>
  )
}

export default DesktopHomepageGenericBanner
